<template>
  <div>
    <b-row class="align-self-stretch">
      <b-col
          sm="12"
          class="h-100"
      >
        <b-overlay :show="loading">
          <b-card
              title="Tárgyév választó"
          >
            <b-form-group
              description="Válaszd ki, melyik tárgyévnek a statisztikáit szeretnéd látni."
            >
              <v-select
                  v-model="referenceYear"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :clearable="false"
                  size="xl"
                  :options="referenceYearOptions"
                  @input="initData"
              />
            </b-form-group>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-overlay :show="loading">
          <b-card>
            <b-card-body>
              <b-card-text class="overflow-x-scroll">
                <b-table-simple>
                  <b-thead>
                    <b-tr>
                      <b-th :key="code" v-for="(stat, code) in statistics">
                        {{ stat.fishingSpot.code }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Lefoglalt turnusok</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td :key="code" v-for="(stat, code) in statistics">
                        {{ stat.data.tournaments }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Horgászok</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td :key="code" v-for="(stat, code) in statistics">
                        {{ stat.data.fishermen }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Gyermekek</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td :key="code" v-for="(stat, code) in statistics">
                        {{ stat.data.children }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Kísérők</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td :key="code" v-for="(stat, code) in statistics">
                        {{ stat.data.attendants }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Vendégnapok</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td :key="code" v-for="(stat, code) in statistics">
                        {{ stat.data.guestDays }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Foglalási díj</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td style="width: 1%; white-space: nowrap" :key="code" v-for="(stat, code) in statistics">
                        {{ $formatters.formatPriceText(stat.data.fees) }} Ft
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th :colspan="Object.keys(statistics).length">Kihasználtság</b-th>
                    </b-tr>
                    <b-tr>
                      <b-td style="width: 1%; white-space: nowrap" :key="code" v-for="(stat, code) in statistics">
                        <span v-if="stat.utilization.reserved === 0 && stat.utilization.available === 0">0% (0/0)</span>
                        <span v-else>
                        {{
                            $formatters.formatPriceText(stat.utilization.reserved / stat.utilization.available * 100, 1)
                          }}% ({{ stat.utilization.reserved }}/{{ stat.utilization.available }})
                      </span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BOverlay,
    BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
    BFormGroup
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchReferenceYearsForSelect').then(response => {
      this.referenceYearOptions = response
    }).finally(() => {
      this.initData()
    })
  },
  data() {
    return {
      statistics: '',
      referenceYear: '',
      referenceYearOptions: [],
      loading: false
    }
  },
  methods: {
    initData() {

      let referenceYear = ''
      if (this.referenceYear !== '') {
        referenceYear = this.referenceYear.value
      }

      this.$store.dispatch('fetchReservationStatistics', {referenceYear: referenceYear}).then(response => {
        this.statistics = response.data.entities
        if (this.referenceYear === '') {
          this.referenceYear = {
            value: response.data.currentReferenceYear.id,
            title: response.data.currentReferenceYear.year
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
